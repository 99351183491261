<template>
  <div class="page-content">
    <div>
      <div class="page-sub-title">
        产品版本列表
      </div>
      <el-table
        :data="versionList"
        style="width: 100%"
        border
        header-row-class-name="table-header"
        :span-method="spanMethod"
        :cell-class-name="randerCellClass"
      >
        <el-table-column
          align="center"
          prop="productName"
          label="产品"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="versionName"
          label="版本名称"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="firstPrice"
          label="初装参考价/套(元)"
        >
        
          <template slot-scope="scope">
            {{$commaNum(scope.row.firstPrice)}}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="yearPrice"
          label="年费参考价/套·年(元)"
        >
        
          <template slot-scope="scope">
            {{$commaNum(scope.row.yearPrice)}}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="gifMesNum"
          label="初装赠送短信(条)"
        >
        
          <template slot-scope="scope">
            {{$commaNum(scope.row.gifMesNum)}}
          </template>
        </el-table-column>
        <!-- <el-table-column
          align="center"
          prop="note"
          label="版本内容"
        ></el-table-column> -->
        <el-table-column
          align="center"
          label="状态"
        >
          <template slot-scope="scope">
            <div class="status-div">
              <span class="status-point"></span>
              {{versionMap[scope.row.status]}}
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { get_product_list } from "@/api/product";
import { versionConst, versionMap } from '@/config/product-config'

export default {
  name: "product",
  components: {
  },
  data() {
    return {
      versionMap,
      productList: [],
      versionList: []
    }
  },
  methods: {
    getList() {
      this.versionList = []
      get_product_list()
        .then(res => {
          this.productList = res
          res.map(o => {
            this.versionList = this.versionList.concat(o.productList)
          })
        })
    },
    spanMethod({ row, columnIndex, rowIndex }) {
      const index = this.productList.findIndex(o => { return o.productName == row.productName })
      const length = index > -1 ? this.productList[index].productList.length : 1
      if (columnIndex == 0) {
        if (rowIndex % length === 0) {
          return {
            rowspan: length,
            colspan: 1
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0
          }
        }
      }
    },
    randerCellClass({ row, columnIndex }) {
      if (row.status == versionConst.ONSALE || columnIndex == 0) {
        return 'cell-valid'
      } else {
        return 'cell-invalid'
      }
    }
  },
  mounted() {
    this.getList();
  }
}
</script>

<style scoped lang="scss">
.table-title{
  font-size: 16px;
  color: #272727;
  line-height: 50px;
  text-align: left;
}
.status-point{
  width: 6px;
  height: 6px;
  border-radius: 50%;
  display: inline-block;
  vertical-align: 2px;
}
.cell-valid{
  .status-div{
    color: #67C23A;
    .status-point{
      background: #67C23A;
    }
  }
}
.cell-invalid{
  .status-div{
    color:#A4A4A4;
    .status-point{
      background: #CCCCCC;
    }
  }
}
::v-deep .cell-invalid .cell{
  color:#A4A4A4;
}
</style>