export const versionConst = {
  TOBESALE: 0,
  ONSALE: 1,
  OFFSALE: 2
}

export const versionMap = {
  [versionConst.TOBESALE]: '未开售',
  [versionConst.ONSALE]: '已开售',
  [versionConst.OFFSALE]: '已下架'
}